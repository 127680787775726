<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <c-update-btn 
          name="updateBtn"
          :data="param.planUpdateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld" 
          label="LBLREMOVE" 
          icon="remove" 
          @btnClicked="removePlan" />
        <c-btn 
          v-show="editable&&!disabled"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="LBLCOMPLETE" 
          icon="check"
          @beforeAction="completePlan"
          @btnCallback="completePlanCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :assessPlan.sync="assessPlan"
          :attachInfo.sync="attachInfo"
          :searchFlag.sync="searchFlag"
          :updateBtnData.sync="param.planUpdateBtnData"
          @loadAssess="loadAssess"
          @saveCallback="saveCallback"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazop-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: 5,
      tab: 'planInfo',
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regDt: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HAZOP_PLAN',
        taskKey: '',
      },
      searchFlag: {
        value: '',
      },
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      loadUrl: '',
      isComplete: false,
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if ((this.editable && !this.disabled) || this.btnEditable) {
        numHeight = numHeight - 20
      }
      return numHeight + 'px';
    },
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '평가계획', component: () => import(`${'./hazopPlanInfo.vue'}`) },
        { key: uid(), name: 'team', icon: 'checklist', label: '평가팀', component: () => import(`${'./hazopAnalysisTeam.vue'}`), disabled: (this.param.ramRiskAssessmentPlanId ? false : true) },
        { key: uid(), name: 'targetProcess', icon: 'category', label: '대상공정', component: () => import(`${'./hazopTargetProcess.vue'}`), disabled: (this.param.ramRiskAssessmentPlanId ? false : true) },
      ]
    },
    isOld() {
      return Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    deleteDisabled() {
      return this.assessPlan.ramStepCd === 'RRS0000015'
    },
    disabled() {
      return this.assessPlan.ramStepCd !== 'RRS0000001' || !this.assessPlan.ramRiskAssessmentPlanId
    },
    btnEditable() {
      return this.editable && this.assessPlan.ramStepCd !== 'RRS0000001' && Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    flagCondition() {
      return this.assessPlan.ramStepCd === 'RRS0000001'
    },
  },
  watch: {
    'param.planUpdateBtnData.planResearch'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.ram.hazop.get.url;
      this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      this.deleteUrl = transactionConfig.ram.assessPlan.delete.url
      this.loadUrl = transactionConfig.ram.assessPlan.load.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.ramRiskAssessmentPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.param.ramRiskAssessmentPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.assessPlan, _result.data);
          this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
            [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []
          this.$set(this.attachInfo, 'taskKey', this.assessPlan.ramRiskAssessmentPlanId)
          this.searchFlag.value = uid();
        },);
      } else {
        this.assessPlan.regUserId = this.$store.getters.user.userId
        this.assessPlan.regUserName = this.$store.getters.user.userName
        this.assessPlan.regDt = this.$comm.getTodayDateTime();
        this.assessPlan.ramTechniqueCd = 'RT00000001'
      }
    },
    loadAssess(ramRiskAssessmentPlanId) {
      this.$http.url = this.loadUrl;
      this.$http.type = 'POST';
      this.$http.param = {
        ramRiskAssessmentPlanId: ramRiskAssessmentPlanId,
        ramTechniqueCd: 'RT00000001'
      };
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.saveCallback(_result.data)
      },);
    },
    saveCallback(data) {
      this.$set(this.param, 'ramRiskAssessmentPlanId', data)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: data
      })
      this.$set(this.tabItems[1], 'disabled', false)
      this.$set(this.tabItems[2], 'disabled', false)
      this.getDetail();
    },
    completePlan() {
      // 평가팀이 구성되었는지?
      // 회의를 진행했는지?
      // 대상공정을 지정했는지?
      // 대상공정별 도면/노드가 하나 이상 지정되었는지?
      let isProgress = true;
      if (!this.assessPlan.teams || this.assessPlan.teams.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '평가팀이 구성되지 않았습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else if (!this.assessPlan.processes || this.assessPlan.processes.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '지정된 대상공정이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(this.assessPlan.processes, process => {
          if (!process.assessUserId) {
            isProgress = false
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '대상공정[' + process.processName + ']에 상세정보가 입력되지 않았습니다.',
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else if (!process.drawNodes || process.drawNodes.length === 0) {
            isProgress = false
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '대상공정[' + process.processName + ']에 도면&노드가 입력되지 않았습니다.',
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else {
            this.$_.forEach(process.drawNodes, drawNode => {
              if (drawNode.editFlag === 'C' || drawNode.editFlag === 'U') {
                isProgress = false
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: '대상공정[' + process.processName + '] / 도면[' + drawNode.diagramTitle + '] / 노드[' + drawNode.nodeTitle + '] 정보가 입력중에 있습니다.\n\r입력을 마무리 한 후 저장하신 다음 이용하시기 바랍니다.',
                  type: 'warning', // success / info / warning / error
                });
                return false;
              } else if (!drawNode.nodeNo) {
                isProgress = false
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: '대상공정[' + process.processName + '] / 도면[' + drawNode.diagramTitle + ']에 노드정보가 입력되지 않았습니다.\n\r입력을 마무리 한 후 저장하신 다음 이용하시기 바랍니다.',
                  type: 'warning', // success / info / warning / error
                });
                return false;
              }

              if (!isProgress) {
                return false;
              }
            })
          }
        });

        if (isProgress) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessPlan.chgUserId = this.$store.getters.user.userId
              this.assessPlan.ramStepCd = 'RRS0000010'

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    completePlanCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.assessPlan.ramStepCd
      })
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.assessPlan.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
  }
};
</script>
